/**
 * Import Global Vendor Libraries
 *
 * TODO: Find a way to import Font Awesome in a better way
 */
import '@babel/polyfill';
import './../node_modules/@fortawesome/fontawesome-free/js/all.js';

/**
 * Website main functionality
 */
import './js/main.js';